import React, { FC, useEffect } from "react";
import UpcReportsApi from "api/upc-reports";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import useUpcDetails from "util/hooks/useUpcDetails";

import BasicDetails, {
  BasicDetailsType,
  type BasicDetail
} from "components/molecules/BasicDetails";

import { useLocation } from "react-router-dom";

interface Props {
  title: string;
}

const UPCDetails: FC<Props> = ({ title }) => {
  const { state: upcData, updateState: updateUpcData } = useUpcDetails();
  const enquiryId = useEnquiryId();
  const { search } = useLocation();

  useEffect(() => {
    const api = new UpcReportsApi();

    const params = new URLSearchParams(search);
    const shareToken = params.get("token");

    api
      .getDetails({ enquiryId, shareToken })
      .then(({ response }) => {
        if (response) {
          updateUpcData(response);
        }
      })
      .catch(() => {});
  }, [enquiryId, search, updateUpcData]);

  const {
    name,
    jurisdiction,
    natureOfBusiness,
    anyParentStateOwned,
    operatingRevenue
  } = upcData;

  const upcDetails: BasicDetail[][] = [
    [
      {
        title: "Name",
        itemType: BasicDetailsType.text,
        items: name
          ? [
              {
                value: name.text,
                sources: name.sources
              }
            ]
          : []
      }
    ],
    [
      {
        title: "Jurisdiction",
        itemType: BasicDetailsType.country,
        items:
          jurisdiction && jurisdiction.countryCode && jurisdiction.countryName
            ? [
                {
                  code: jurisdiction.countryCode,
                  name: jurisdiction.countryName,
                  sources: jurisdiction.sources
                }
              ]
            : []
      }
    ],
    [
      {
        title: "Nature of business",
        itemType: BasicDetailsType.text,
        items: natureOfBusiness
          ? [
              {
                value: natureOfBusiness.text,
                sources: natureOfBusiness.sources
              }
            ]
          : []
      },
      {
        title: "Operation revenue",
        itemType: BasicDetailsType.text,
        items: operatingRevenue
          ? [
              {
                value: operatingRevenue.text,
                sources: operatingRevenue.sources
              }
            ]
          : []
      }
    ],
    [
      {
        title: "State ownership",
        itemType: BasicDetailsType.text,
        items: anyParentStateOwned
          ? [
              {
                value: anyParentStateOwned.value ? "Yes" : "No",
                sources: anyParentStateOwned.sources
              }
            ]
          : []
      }
    ]
  ];

  return <BasicDetails title={title} details={upcDetails} />;
};

export default UPCDetails;
