import { styled } from "@linaria/react";

import { grey, blue } from "styles/colors";
import { fontSize } from "styles/fonts";
import { Info } from "react-feather";

const Container = styled.div`
  padding: 16px;
  color: ${grey.dark};
  display: flex;
  gap: 16px;
  justify-content: space-between;
  font-size: ${fontSize.sm};
`;

const Controls = styled.div`
  display: flex;
  gap: 8px;
`;

const Content = styled.div`
  font-size: ${fontSize.xs};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Link = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${blue.link};
`;

const InfoIcon = styled(Info)`
  width: 14px;
  height: 14px;
`;

const S = {
  Container,
  Controls,
  Content,
  Link,
  InfoIcon
};

export default S;
