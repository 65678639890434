import React from "react";

import Pill from "components/atoms/Pill";
import Popover from "components/atoms/Popover";
import { X as XIcon } from "react-feather";

import useEntityList from "util/hooks/useEntityList";
import S from "./styles";

const EntityListControls = () => {
  const {
    state: { filters, activeFilters, details },
    updateActiveFilter
  } = useEntityList();

  return (
    <S.Container>
      <S.Controls>
        Filter by:{" "}
        {filters?.map(({ label, count, filterBy }) => {
          const isActive = activeFilters?.includes(filterBy);
          return (
            <Pill
              key={`EntityListControl-${label}`}
              isDisabled={count === 0}
              rightAddon={isActive ? <XIcon /> : count}
              isActive={isActive}
              onClick={() => updateActiveFilter(filterBy)}
            >
              {label}
            </Pill>
          );
        })}
      </S.Controls>
      {details && (
        <S.Content>
          <span>
            {details?.label} <strong>{details?.value}</strong>
          </span>
          <S.Link>
            <Popover
              arrowColor="white"
              hideArrow
              alignment="bottom-end"
              maxWidth="360px"
              content={details?.helpText}
            >
              <S.InfoIcon /> Learn more
            </Popover>
          </S.Link>
        </S.Content>
      )}
    </S.Container>
  );
};

export default EntityListControls;
