import React, { type FC } from "react";

import { standardColors } from "styles/colors";
import { type EntityRiskFlags, EntityType } from "api/entities";
import Popover from "components/atoms/Popover";

import S from "./styles";

interface Props {
  riskFlags?: EntityRiskFlags;
  entityType: EntityType;
}

const riskIconsByEntityType = {
  [EntityType.organisation]: [
    {
      flag: "sanctions",
      icon: <S.SanctionsIcon />,
      activeTooltip: "We have identified the subject as sanctioned",
      inactiveTooltip: "Sanctions"
    },
    {
      flag: "watchlists",
      icon: <S.WatchlistsIcon />,
      activeTooltip: "We have identified the subject on a watchlist",
      inactiveTooltip: "Watchlists"
    },
    {
      flag: "financial_crimes",
      icon: <S.FinancialCrimesIcon />,
      activeTooltip: "Financial crime flagged for review",
      inactiveTooltip: "Financial Crimes"
    },
    {
      flag: "proceedings",
      icon: <S.ProceedingsIcon />,
      activeTooltip: "Legal proceedings flagged for review",
      inactiveTooltip: "Proceedings"
    }
  ],
  [EntityType.person]: [
    {
      flag: "sanctions",
      icon: <S.SanctionsIcon />,
      activeTooltip: "We have identified the subject as sanctioned",
      inactiveTooltip: "Sanctions"
    },
    {
      flag: "watchlists",
      icon: <S.WatchlistsIcon />,
      activeTooltip: "We have identified the subject on a watchlist",
      inactiveTooltip: "Watchlists"
    },
    {
      flag: "peps",
      icon: <S.PepsIcon />,
      activeTooltip:
        "We have identified the subject as a Politically Exposed Person (PEP)",
      inactiveTooltip: "Politically Exposed Person (PEP)"
    },
    {
      flag: "financial_crimes",
      icon: <S.FinancialCrimesIcon />,
      activeTooltip: "Financial crime flagged for review",
      inactiveTooltip: "Financial Crimes"
    },
    {
      flag: "proceedings",
      icon: <S.ProceedingsIcon />,
      activeTooltip: "Legal proceedings flagged for review",
      inactiveTooltip: "Proceedings"
    }
  ]
};

const EntityRiskIcons: FC<Props> = ({ entityType, riskFlags }) => {
  const riskIcons = riskIconsByEntityType[entityType];
  return (
    <S.RiskIcons>
      {riskIcons.map(({ flag, icon, activeTooltip, inactiveTooltip }) => {
        const isActive = !!(riskFlags && flag in riskFlags);

        return (
          <Popover
            key={flag}
            content={
              <S.Tooltip>
                {isActive ? activeTooltip : inactiveTooltip}
              </S.Tooltip>
            }
            hideArrow
            arrowColor={standardColors.white}
          >
            <S.RiskIcon isActive={isActive}>{icon}</S.RiskIcon>
          </Popover>
        );
      })}
    </S.RiskIcons>
  );
};

export default EntityRiskIcons;
