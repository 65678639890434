import { v4 as uuidv4 } from "uuid";
import { InformationSource } from "api/report/report-types";
import {
  type RoleItem,
  InfoListType,
  EntityType,
  RoleList,
  LocationList,
  TagList
} from "api/entities/types";
import { type model_RelatedParty } from "api/related-parties";

import { getEntityTagItems } from "./getEntityTagItems";
import { getDurationInMonths } from "./getDurationInMonths";

export const transformPersonEntity = ({
  bvdId,
  informationSources,
  name,
  relatedPartyId: id,
  relatedCompany,
  relationships,
  locations: locationsResponse,
  roles: rolesResponse
}: model_RelatedParty) => {
  const roles: RoleList = {
    type: InfoListType.roles,
    items:
      rolesResponse?.map(({ roleTitle, dateFrom, dateTo }): RoleItem => {
        const dateFromDate =
          dateFrom?.day && dateFrom?.month && dateFrom?.year
            ? new Date(dateFrom.year, dateFrom.month, dateFrom.day)
            : undefined;
        const dateToDate =
          dateTo?.day && dateTo?.month && dateTo?.year
            ? new Date(dateTo.year, dateTo.month, dateTo.day)
            : undefined;

        const durationInMonths = getDurationInMonths(dateFromDate, dateToDate);

        return {
          id: uuidv4(),
          title: roleTitle,
          company: relatedCompany,
          durationInMonths,
          dateFrom: dateFromDate,
          dateTo: dateToDate
        };
      }) ?? []
  };

  const locations: LocationList = {
    type: InfoListType.locations,
    items:
      locationsResponse?.map(({ city, country }) => ({
        id: uuidv4(),
        value: [city, country?.countryName].filter(Boolean).join(", ")
      })) ?? []
  };

  // TODO: This business logic should live on the BE
  const hasReportableData = !!bvdId;

  const tags: TagList = {
    type: InfoListType.tags,
    items: getEntityTagItems({ relationships })
  };

  const sources = informationSources
    ? (informationSources as unknown as InformationSource[])
    : [];

  return {
    id,
    hasReportableData,
    entityType: EntityType.person,
    title: name,
    sources,
    lists: [roles, locations, tags]
  };
};
