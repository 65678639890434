import {
  CollectionListState,
  CollectionStatus,
  COLLECTION_ITEMS_PER_PAGE,
  CollectionInputType
} from "util/hooks/useCollectionList/types";
import { CollectionListView } from "components/molecules/CollectionListControls";

import { CollectionSearchState } from "util/hooks/useCollectionSearch/types";
import {
  Idam_Contracts_Reports_GetReportPersonasResponse,
  ReportService
} from "api/portal";
import { apm } from "@elastic/apm-rum";
import { FetchResult } from "api/types";
import { getErrorMessage } from "api/util";
import { ReportPersona } from "./types";

export const REPORT_PERSONAS_COLLECTION = "report-personas";

export default class ReportPersonas {
  getEmptyCollections(): CollectionListState {
    return {
      collections: [
        {
          id: REPORT_PERSONAS_COLLECTION,
          title: "All Personas",
          limit: COLLECTION_ITEMS_PER_PAGE,
          offset: 0,
          order: "desc",
          view: CollectionListView.grid,
          pollingEnabled: true,
          items: [],
          totalItemCount: 0,
          status: CollectionStatus.stale,
          hidden: false,
          hiddenIfEmpty: false,
          input: {
            type: CollectionInputType.list
          }
        }
      ]
    };
  }

  async list({
    filterByEnquiryId
  }: {
    filterByEnquiryId?: string;
  }): Promise<{ items: ReportPersona[]; totalItemCount: number }> {
    if (!filterByEnquiryId) {
      return { items: [], totalItemCount: 0 };
    }

    const { status, response, message } = await this.getAllReportPersonas(
      filterByEnquiryId
    );

    if (status && response) {
      const reportPersonas: ReportPersona[] = response.map(
        ({ personaId: id, displayName: title, subjectScore, numberOfDres }) => {
          return {
            id: id ?? "",
            title: title ?? "",
            tags: [],
            doB: {
              earliestPossible: null,
              latestPossible: null
            },
            nationalities: [],
            mainPersonaMatchScore: subjectScore ?? 0,
            hasPersonaReport: true,
            resolutionAccuracyWarnings: [],
            numberOfDres
          };
        }
      );

      reportPersonas.sort((a, b) => {
        if (b.mainPersonaMatchScore === undefined) return 1;
        if (a.mainPersonaMatchScore === undefined) return -1;
        return b.mainPersonaMatchScore - a.mainPersonaMatchScore;
      });

      return { items: reportPersonas, totalItemCount: reportPersonas.length };
    }
    console.error(message);
    return { items: [], totalItemCount: 0 };
  }

  getEmptySearchState(): CollectionSearchState {
    return {
      query: "",
      results: [],
      searchTags: []
    };
  }

  async search() {
    return { items: [], totalItemCount: 0 };
  }

  async getAllReportPersonas(
    enquiryId: string
  ): Promise<FetchResult<Idam_Contracts_Reports_GetReportPersonasResponse[]>> {
    try {
      const response = await ReportService.getReportsPersonas({
        reportId: enquiryId
      });
      // TODO: this will be updated once I have IDAM swagger.
      return { status: true, response };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }
}
