import { styled } from "@linaria/react";

const Container = styled.div<{ isExpanded?: boolean }>`
  max-height: ${({ isExpanded }) => (isExpanded ? "none" : "238px")};
  overflow-y: ${({ isExpanded }) => (isExpanded ? "auto" : "scroll")};
`;

const Divider = styled.hr`
  margin: 0;
`;

const S = {
  Container,
  Divider
};

export default S;
