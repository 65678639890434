import { RiskSummaryTopic } from "api/insights";
import { RiskFactorName } from "api/risk-service";

import { ReactComponent as FinCrimeRiskFlag } from "img/icons/fin-crime.svg";
import { ReactComponent as RiskFlag } from "img/icons/flag-icon.svg";
import { ReactComponent as ESGSvg } from "img/icons/esg-icon.svg";
import { ReactComponent as MajorCrimesSvg } from "img/icons/law-icon.svg";
import { ReactComponent as SanctionsSvg } from "img/icons/sanctions-icon.svg";
import { ReactComponent as WatchlistsSvg } from "img/icons/watchlists-icon.svg";
import { ReactComponent as LocationRiskSvg } from "img/icons/location-risk.svg";
import { ReactComponent as RelatedPartiesSvg } from "img/icons/related-parties.svg";

import {
  ReactComponent as PepsSvg,
  ReactComponent as StateOwnedSvg
} from "img/icons/state-owned.svg";
import { ReactComponent as SpecialInterestSvg } from "img/icons/special-interest-icon.svg";

export const getRiskSummaryIcon = (topic: RiskSummaryTopic) => {
  switch (topic) {
    case RiskSummaryTopic.SANCTIONS:
      return SanctionsSvg;
    case RiskSummaryTopic.WATCHLISTS:
      return WatchlistsSvg;
    case RiskSummaryTopic.PEPS:
      return PepsSvg;
    case RiskSummaryTopic.FINANCIAL_CRIME:
      return FinCrimeRiskFlag;
    case RiskSummaryTopic.ESG:
      return ESGSvg;
    case RiskSummaryTopic.PROCEEDINGS:
      return MajorCrimesSvg;
    case RiskSummaryTopic.SPECIAL_INTEREST:
      return SpecialInterestSvg;
    case RiskSummaryTopic.STATE_OWNED:
      return StateOwnedSvg;
    default:
      return RiskFlag;
  }
};

export const getRiskSummaryTitle = (topic: RiskSummaryTopic) => {
  switch (topic) {
    case RiskSummaryTopic.SANCTIONS:
      return "Sanctions";
    case RiskSummaryTopic.WATCHLISTS:
      return "Watchlists";
    case RiskSummaryTopic.PEPS:
      return "PEPs";
    case RiskSummaryTopic.FINANCIAL_CRIME:
      return "Financial Crime";
    case RiskSummaryTopic.ESG:
      return "ESG";
    case RiskSummaryTopic.PROCEEDINGS:
      return "Proceedings";
    case RiskSummaryTopic.SPECIAL_INTEREST:
      return "Special Interest";
    case RiskSummaryTopic.STATE_OWNED:
      return "State owned";
    default:
      return "Other";
  }
};

export const getRiskFactorIcon = (topic: RiskFactorName) => {
  switch (topic) {
    case RiskFactorName.SanctionsRiskFactorName:
      return SanctionsSvg;
    case RiskFactorName.WatchlistsRiskFactorName:
      return WatchlistsSvg;
    case RiskFactorName.PEPsRiskFactorName:
      return PepsSvg;
    case RiskFactorName.FinancialCrimeRiskFactorName:
      return FinCrimeRiskFlag;
    case RiskFactorName.ESGRiskFactorName:
      return ESGSvg;
    case RiskFactorName.LocationsRiskFactorName:
      return LocationRiskSvg;
    case RiskFactorName.StateOwnershipRiskFactorName:
      return StateOwnedSvg;
    case RiskFactorName.LegalAndComplianceRiskFactorName:
      return MajorCrimesSvg;
    case RiskFactorName.RelatedPartiesRiskFactorName:
      return RelatedPartiesSvg;
    case RiskFactorName.OtherRiskFactorName:
    default:
      return RiskFlag;
  }
};

export const getRiskFactorTitle = (topic: RiskFactorName) => {
  switch (topic) {
    case RiskFactorName.SanctionsRiskFactorName:
      return "Sanctions";
    case RiskFactorName.WatchlistsRiskFactorName:
      return "Watchlists";
    case RiskFactorName.PEPsRiskFactorName:
      return "PEPs";
    case RiskFactorName.FinancialCrimeRiskFactorName:
      return "Financial Crime";
    case RiskFactorName.ESGRiskFactorName:
      return "ESG";
    case RiskFactorName.LocationsRiskFactorName:
      return "Locations";
    case RiskFactorName.StateOwnershipRiskFactorName:
      return "State Ownership";
    case RiskFactorName.LegalAndComplianceRiskFactorName:
      return "Legal & compliance";
    case RiskFactorName.RelatedPartiesRiskFactorName:
      return "Related parties";
    case RiskFactorName.OtherRiskFactorName:
    default:
      return "Other";
  }
};
